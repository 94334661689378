import React from "react";
//import axios from "axios";
//import { Link } from "react-router-dom";
import {
  makeStyles,
  Card,
  CardContent,
  //CardMedia,
  Typography,
  CardHeader,
  Grid,
} from "@material-ui/core";
import "./App.css";

const useStyles = makeStyles({
  card: {
    background: "#f3f3f3",
    maxWidth: "400px",
    height: "300px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5,
    //border: "1px solid #0c3c5c",
    //boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
    //padding: "0px",
  },
  titleHead: {
    color: "#ffffff",
    background: "#0c3c5c",
    border: "5px solid #0c3c5c",
    fontWeight: "bold",
    textAlign: "center",
  },
  content: {
    textAlign: "center",
    //fontStyle: "italic",
    fontFamily: "courier",
    fontWeight: "bold",
  },
  price: {
    color: "#0c3c5c",
    border: "5px solid #f3f3f3",
    fontFamily: "courier",
    fontWeight: "bold",
    fontSize: 35,
  },
  class_length: {
    color: "#0c3c5c",
    border: "5px solid #f3f3f3",
    fontFamily: "courier",
    fontWeight: "bold",
  },
  under_score: {
    color: "#0c3c5c",
    border: "5px solid #f3f3f3",
    // borderBottom: "1px solid #0c3c5c",
    // fontWeight: "bold",
  },
  freq: {
    color: "#0c3c5c",
    border: "5px solid #f3f3f3",
    fontFamily: "courier",
    fontWeight: "bold",
  },
  grid: {
    flexGrow: 1,
    flexDirection: "row",
    //border: "10px",
    //margin: "10px",
    marginTop: "20px",
    marginBottom: "40px",
  },
  media: {
    height: "100%",
    width: "100%",
  },
  button: {
    color: "#1e8678",
    fontWeight: "bold",
    fontSize: 12,
  },
  absentNotice: {
    //textAlign: "center",
    //marginleft: "30%",
    paddingLeft: "5%",
    paddingRight: "5%",
    //textAlign: "center",
    //maxWidth: "40%",
  },
  col_6: {
    width: "50%",
    //textAlign: "center",
  },
});

export default function Pricing() {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.grid} spacing={7}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ fontFamily: "courier" }}
                >
                  <b>Private Lesson (1:1)</b>
                </Typography>
              }
            />
            <CardContent className={classes.content}>
              <Typography className={classes.price}>$380 / month</Typography>
              <Typography className={classes.class_length}>
                Class Length: 60 minutes
              </Typography>
              <Typography className={classes.under_score}>
                ______________________________
              </Typography>
              <Typography className={classes.freq}>
                Lessons Once a Week
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ fontFamily: "courier" }}
                >
                  <b>Private Lessons (1:1)</b>
                </Typography>
              }
            />
            <CardContent className={classes.content}>
              <Typography className={classes.price}>$200 / month</Typography>
              <Typography className={classes.class_length}>
                Class Length: 30 minutes
              </Typography>
              <Typography className={classes.under_score}>
                ______________________________
              </Typography>
              <Typography className={classes.freq}>
                Lessons Once a Week
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ fontFamily: "courier" }}
                >
                  <b>Semi-Private Lessons (1:2)</b>
                </Typography>
              }
            />
            <CardContent className={classes.content}>
              <Typography className={classes.price}>$260 / month</Typography>
              <Typography className={classes.class_length}>
                Class Length: 60 minutes
              </Typography>
              <Typography className={classes.under_score}>
                ______________________________
              </Typography>
              <Typography className={classes.freq}>
                Lessons Once a Week
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ fontFamily: "courier" }}
                >
                  <b>Group Lessons (1:4)</b>
                </Typography>
              }
            />
            <CardContent className={classes.content}>
              <Typography className={classes.price}>$200 / month</Typography>
              <Typography className={classes.class_length}>
                Class Length: 45 minutes
              </Typography>
              <Typography className={classes.under_score}>
                ______________________________
              </Typography>
              <Typography className={classes.freq}>
                Lessons Once a Week
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ fontFamily: "courier" }}
                >
                  <b>Team (1:6)</b>
                </Typography>
              }
            />
            <CardContent className={classes.content}>
              <Typography className={classes.price}>$160 / month</Typography>
              <Typography className={classes.class_length}>
                Class Length: 60 minutes
              </Typography>
              <Typography className={classes.under_score}>
                ______________________________
              </Typography>
              <Typography className={classes.freq}>
                Lessons Once a Week
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div style={{ display: "flex" }}>
        <div className={classes.col_6}>
          <p className={classes.absentNotice} style={{ fontWeight: "bold" }}>
            Absent & Makeup Classes
          </p>
          <p className={classes.absentNotice}>
            · Makeup classes are allowed within the term for each individual
            swimmer.
          </p>
          <p className={classes.absentNotice}>
            · NO makeup class will be granted if the swimmer did not give out
            the notice 24 hours before the class starts.
          </p>
        </div>
        <div className={classes.col_6}>
          <p className={classes.absentNotice} style={{ fontWeight: "bold" }}>
            Registration & Fees
          </p>
          <p className={classes.absentNotice}>
            · Swimmers will be considered successfully registered after payment
            has been made.
          </p>
          <p className={classes.absentNotice}>· NO REFUND.</p>
        </div>
      </div>
    </div>
  );
}
